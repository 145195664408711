import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { take, slice, head } from '../../lib/nodash';
import { navigate } from 'gatsby';

import UgcGalleryItem from './UgcGalleryItem';
import { getProductPath } from '../../lib/product';
import useMobile from '../useMobile';

const GALLERY_URL = '/gallery';

const SalonGalleryGroup = ({ items, index }) => {
  const isSmall = useMobile();
  const isAlternate = index % 2 > 0;
  const limited = take(7, items);
  const groupA = take(isAlternate ? 4 : 3, limited);
  const groupB = slice(isAlternate ? 4 : 3, limited.length, limited);

  const goToPdp = (id, products) => {
    if (isSmall) {
      return navigate('/pages/gallery');
    } else {
      return navigate(getProductPath(head(products)));
    }
  };

  return (
    <Fragment>
      <Box direction="row" gap="xxsmall" fill="horizontal">
        <Box
          className="section1"
          direction={isAlternate ? 'row' : 'column'}
          gap="xxsmall"
          basis={isAlternate ? '55%' : '45%'}
        >
          <Box
            className="section1-1"
            direction={isAlternate ? 'column' : 'row'}
            gap="xxsmall"
            basis={isAlternate ? '50%' : '45%'}
          >
            {take(2, groupA)
              .filter(hasUgc)
              .map((x, i) => (
                <UgcGalleryItem
                  {...x}
                  key={x.id}
                  basis={isAlternate ? (i === 0 ? '45%' : '55%') : '50%'}
                  tagSize="small"
                  handleClick={goToPdp}
                  linkToProduct={true}
                  mobileLink={GALLERY_URL}
                  imageOptions={{ w: 150, q: 30 }}
                  lazy={true}
                />
              ))}
          </Box>
          <Box
            className="section1-2"
            direction="column"
            basis={isAlternate ? '50%' : '55%'}
            gap="xxsmall"
          >
            {slice(isAlternate ? 2 : 2, 4, groupA)
              .filter(hasUgc)
              .map((x, i) => (
                <UgcGalleryItem
                  {...x}
                  key={x.id}
                  basis={i === 0 ? '55%' : '45%'}
                  tagSize="small"
                  handleClick={goToPdp}
                  linkToProduct={true}
                  mobileLink={GALLERY_URL}
                  imageOptions={{ w: isAlternate ? 150 : 200, q: 75 }}
                  lazy={true}
                />
              ))}
          </Box>
        </Box>

        <Box
          className="section2"
          gap="xxsmall"
          direction={isAlternate ? 'column' : 'row'}
          basis={isAlternate ? '45%' : '55%'}
        >
          <Box
            className="section2-1"
            direction={isAlternate ? 'row' : 'column'}
            gap="xxsmall"
            basis={isAlternate ? '55%' : '50%'}
          >
            {take(isAlternate ? 1 : 2, groupB)
              .filter(hasUgc)
              .map((x, i) => (
                <UgcGalleryItem
                  {...x}
                  key={x.id}
                  basis={isAlternate ? '50%' : i === 0 ? '45%' : '55%'}
                  tagSize="small"
                  handleClick={goToPdp}
                  linkToProduct={true}
                  mobileLink={GALLERY_URL}
                  imageOptions={{ w: isAlternate ? 250 : 150, q: 75 }}
                  lazy={true}
                />
              ))}
          </Box>
          <Box
            className="section2-2"
            direction={isAlternate ? 'row' : 'column'}
            gap="xxsmall"
            basis={isAlternate ? '45%' : '50%'}
          >
            {slice(isAlternate ? 1 : 2, 4, groupB)
              .filter(hasUgc)
              .map((x, i) => (
                <UgcGalleryItem
                  {...x}
                  key={x.id}
                  basis={isAlternate ? '50%' : i === 0 ? '55%' : '45%'}
                  tagSize="small"
                  handleClick={goToPdp}
                  linkToProduct={true}
                  mobileLink={GALLERY_URL}
                  imageOptions={{ w: 150, q: 75 }}
                  lazy={true}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

SalonGalleryGroup.propTypes = {
  items: PropTypes.array,
  index: PropTypes.number,
};

export default memo(SalonGalleryGroup);

function hasUgc(x) {
  return x.ugc && x.ugc.file;
}
