import React from 'react';
import PropTypes from 'prop-types';
import { compact, take } from '../../lib/nodash';
import { Box } from 'grommet';
import SalonGalleryGroup from './SalonGalleryGroup';

import useMobile from '../useMobile';
import chunkArray from '../../lib/chunkArray';

const SalonGallery = ({ placements, ...rest }) => {
  const isMobile = useMobile();

  const groups = chunkArray(compact(placements), 7);

  return (
    <Box
      direction="row"
      gap="xxsmall"
      className="Salon-Gallery-outer"
      fill="horizontal"
      height={isMobile ? '60' : '35vw'}
      {...rest}
    >
      {(isMobile ? take(1, groups) : groups).map((x, i) => (
        <SalonGalleryGroup key={`group-${i}`} items={x} index={i} />
      ))}
    </Box>
  );
};

SalonGallery.propTypes = {
  placements: PropTypes.array,
};

export default SalonGallery;
