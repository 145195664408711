import {
  lowerCase,
  join,
  reject,
  map,
  get,
  filter,
  startCase,
  flatten,
  replace,
} from './nodash';
import { isPaint } from './product';
import { NON_DISPLAY_TAGS } from './constants';

export const generateAltText = (ugc, products) => {
  const paintProducts = filter(isPaint, products);

  const paintColorTags = map(
    startCase,
    map(
      (x) => replace('color:', '', x),
      reject(
        (y) => NON_DISPLAY_TAGS.indexOf(y) > -1,
        flatten(map('tags', paintProducts))
      )
    )
  );

  const baseDescription = `${map('title', paintProducts).join(', ')}${
    paintProducts.length ? ', ' : ''
  }${map((x) => lowerCase(get('description', x)), products).join(', ')} ${
    paintProducts.length ? `paint color${products.length > 1 ? 's' : ''}` : ''
  } by Backdrop. `;

  const altText = `${baseDescription}– ${reject(
    (x) => NON_DISPLAY_TAGS.indexOf(x) > -1,
    [...paintColorTags, ...map('name', get('tags', ugc))]
  ).join(', ')}`;

  return altText;
};
